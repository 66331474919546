<template>
  <v-dialog v-model="editRfidModal" v-if="editRfidModal && editForm" max-width="600">
    <v-form @submit.prevent="save" autocomplete="nope">
      <v-card>
        <v-card-title>
          {{ $t('rfid.edit-rfid') }}
        </v-card-title>
        <v-card-text>

          <v-row dense>
            <v-col>
              <v-text-field
                  :label="$t('rfid.rfid-card-no')"
                  v-model="editForm.uid"
                  :disabled="isEdit"
                  hide-details
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  :label="$t('rfid.rfid-card-physical-reference')"
                  v-model="editForm.physicalReference"
                  persistent-hint
                  :hint="$t('rfid.rfid-card-physical-reference-hint')"
              />
            </v-col>
            <v-col>
              <v-text-field
                  :label="$t('rfid.rfid-card-name')"
                  v-model="editForm.name"
                  hide-details
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch
                  :label="$t('rfid.rfid-card-enabled')"
                  v-model="editForm.enabled"
                  hide-details
              />
            </v-col>
            <v-col v-if="false">
              <v-switch
                  :label="$t('rfid.rfid-roaming')"
                  v-model="editForm.roaming"
                  hide-details
              />
            </v-col>
            <v-col>
              <v-switch
                  :label="$t('rfid.rfid-corporate')"
                  v-model="editForm.corporate"
                  hide-details
              />
            </v-col>
          </v-row>
          <v-row  v-if="currentUser.isZE">
            <v-col cols="12">
              <v-switch
                  :label=" $t('rfid.rfid-added-by-customer')"
                  v-model="editForm.private"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert  color="orange" dark dense v-if="editForm.organizationPaymentMethodAllowed && editForm.paymentAttachedToOrganization">
                {{ $t('rfid.rfid-use-organization-differed-payment')  }}
              </v-alert>

              <v-alert color="grey" dark dense v-if="editForm.private">
                {{ $t('rfid.rfid-added-by-customer') }} : <b>{{ editForm.private ? $t('yes') : $t('no') }}</b>
              </v-alert>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col>
              <organization-select
                  :label="$t('organization.organization')"
                  v-model="editForm.organizationId"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="!organizationId && !customerId">
            <v-col>
              <customer-select
                  :label="$tc('customer.customer')"
                  v-model="editForm.customerId"
                  :placeholder="$t('rfid.no-customer')"
                  clearable
              />
            </v-col>
          </v-row>

          <v-row dense v-else-if="!customerId">
            <v-col>
              <member-select
                  :label="$tc('member.member')"
                  v-model="editForm.memberId"
                  :organization-id="editForm.organizationId"
                  clearable
              />
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="d-flex justify-space-between align-center">
              <v-btn v-if="editForm.corporate && editForm.organizationPaymentMethodAllowed && !editForm.paymentAttachedToOrganization" x-small @click="attachToOrganizationPaymentMethod">{{ $t('rfid.rfid-attach-to-organization-payment-method') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn small type="button" class="mr-3" @click="editRfidModal = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn small color="primary" type="submit">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TokenRepository from "@repository/TokenRepository";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import MemberSelect from "@blocks/Select/MemberSelect.vue";

export default {
  components: {
    OrganizationSelect,
    CustomerSelect,
    MemberSelect,
  },
  data() {
    return {
      rfidId: null,
      editForm: null,
      editRfidModal: false,
    }
  },

  props: {
    customerId: {type: String, default: null},
    organizationId: {type: String, default: null},
  },

  computed: {
    isEdit() {
      return !!this.rfidId
    }
  },

  methods: {

    open({rfidId = null}) {
      this.rfidId = rfidId
      if(this.isEdit) {
        this.loadData()
      } else {
        this.editForm = {
          uid: null,
          physicalReference: null,
          name: null,
          enabled: true,
          roaming: false,
          corporate: true,
          customerId: null,
          organizationId: null,
          memberId: null,
        }
        this.editRfidModal = true
      }
    },

    close() {
      this.editRfidModal = false
    },


    loadData() {
      this.showLoading(true)
      TokenRepository.get(this.rfidId).then((data) => {

        data.organizationId = data.organization?.id

        if(!this.organizationId) {
          data.customerId = data.customer?.id
        } else {
          data.memberId = data.member?.id

        }
        this.editForm = data;
        this.$nextTick(() => {
          this.editRfidModal = true
        })
        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
        this.showLoading(false)
      })
    },

    save({force = false}) {
      this.showLoading(true)
      const data = this.editForm
      delete data.bigendianValidated
      delete data.createdAt
      delete data.paymentAttachedToOrganization
      delete data.whitelist
      data.force = force

      if(this.isEdit) {
        TokenRepository.update(data).then((data) => {
          this.showLoading(false)
          this.editRfidModal = false
          this.$emit('change', data)
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })
      } else {
        if(data.uid) {
          if(this.customerId) {
            data.customerId = this.customerId
          }
          if(this.organizationId) {
            data.organizationId = this.organizationId
          }
          if(this.memberId) {
            data.memberId = this.memberId
          }
          TokenRepository.create(data).then(() => {
            this.showLoading(false)
            this.editRfidModal = false
            this.$emit('change', data)
          }).catch(err => {
            this.showLoading(false)
            if(err.response?.data?.message === 'Invalid tag') {
              this.$dialog.confirm({text: this.$t('rfid.invalid-tag-confirmation')}).then(result => {
                if (result) {
                  this.save({force: true})
                }
              })
            } else {
              this.notifyError(err)
            }
          })
        }
      }

    },


    attachToOrganizationPaymentMethod() {
      this.showLoading(true)
      TokenRepository.attachToOrganizationPaymentMethod(this.editForm.id).then(() => {
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
